const setModuleSetting = {
  module_maximal_message_daily: 'setMaxNotif',
  module_range_time: 'setRangeTime',
  module_text_message: 'setTextNotif',
  module_status_connection: 'setStatusConnection',
};
const moduleSetting = {
  module_maximal_message_daily: 'module_maximal_message_daily',
  module_range_time: 'module_range_time',
  module_text_message: 'module_text_message',
  module_profile: 'module_profile',
  module_status_connection: 'module_status_connection',
};

module.exports = {
  setModuleSetting,
  moduleSetting,
};
