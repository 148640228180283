import API from '../API/connection';
const firebase = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    isErrorUsage: false,
    successMessage: '',
    errorMessage: '',
  },
  actions: {
    saveTokenFirebase({ commit }, payload) {
      try {
        return API.post('save-token-firebase', payload);
      } catch (error) {
        commit('setErrorMessage', error);
        return error;
      }
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setErrorUsage(state, data) {
      state.isErrorUsage = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
  },
};

export default firebase;
