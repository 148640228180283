import Vue from 'vue';
import Vuex from 'vuex';

import login from './login';
import receiver from './receiver';
import setting from './setting';
import firebase from './firebase';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    login,
    receiver,
    setting,
    firebase,
  },
  strict: 'development',
});
