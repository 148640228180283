import API from '../API/connection';
const receiver = {
  namespaced: true,
  state: {
    items: [],
    item: {},
    totalRows: 0,
    isLoading: false,
    isError: false,
    successMessage: '',
    errorMessage: '',
  },
  actions: {
    async getAllReceiver({ commit }, payload) {
      try {
        commit('setLoading', true);
        const response = await API.get('/receivers', { params: payload });
        commit('setLoading', false);
        commit('setItems', response.data.result.rows);
        commit('setRows', response.data.result.count);
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
        return error;
      }
    },
    async getReceiverById({ commit }, payload) {
      try {
        commit('setLoading', true);
        const response = await API.get('/receiver/' + payload);
        commit('setLoading', false);
        return response.data.result;
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
        return error;
      }
    },
    async submitReceiver({ commit }, payload) {
      commit('setSuccessMessage', null);
      commit('setErrorMessage', null);
      try {
        commit('setLoading', true);
        if (payload.type === 'edit') {
          await API.patch('/receiver', payload);
          commit('setLoading', false);
          commit('setSuccessMessage', 'penerima berhasil diperbarui');
          return;
        }
        await API.post('/receiver', payload);
        commit('setLoading', false);
        commit('setSuccessMessage', 'penerima berhasil ditambahkan');
      } catch (error) {
        commit('setLoading', false);
        commit('setError', true);
        commit('setErrorMessage', 'penerima gagal disubmit');
        return error;
      }
    },
  },
  mutations: {
    setItems(state, item) {
      state.items = item;
    },
    setItem(state, item) {
      state.item = item;
    },
    setRows(state, data) {
      state.totalRows = data;
    },
    setLoading(state, data) {
      state.isLoading = data;
    },
    setError(state, data) {
      state.isError = data;
    },
    setSuccessMessage(state, data) {
      state.successMessage = data;
    },
    setErrorMessage(state, data) {
      state.errorMessage = data;
    },
  },
};

export default receiver;
