import Vue from 'vue';
import VueRouter from 'vue-router';
const Main = () => import('../views/Main.vue');
const Home = () => import('../views/Home.vue');
// const Login = () => import('../views/Login.vue');

const Setting = [require('./setting').Setting];

Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    component: Main,
    children: [
      {
        path: '/',
        name: 'home',
        component: Home,
      },
      ...Setting,
    ],
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: Login,
  // },
  // {
  //   path: '*',
  //   name: 'notFound',
  //   component: NotFound404,
  // },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
