import axios from 'axios';
let clientKey = process.env.VUE_APP_CLIENT_KEY_PUBLIC;
const baseURL = process.env.VUE_APP_BASE_API_URL;

const API_PUBLIC = axios.create({
  baseURL,
});

//Request interceptor for API calls
API_PUBLIC.interceptors.request.use(
  async (config) => {
    config.headers = {
      Accept: 'application/json',
      Authorization: `${clientKey}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export default API_PUBLIC;
